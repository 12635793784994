
  .title {
    font-size: 12em;
    text-align: center;
    text-shadow: 5px 5px #660099;
  }
  
  h1{
    color: #ffffff;
    
  }
  h3 {
    padding-top: 0.25em;
    font-size: 3rem;
    color: #fff;
    text-shadow: 1px 1px #660099;
  }

  .linearBG {

    background: linear-gradient(180deg, #00FF01 0%, #1F5210 100%);
  }
  .vertical-timeline-element-subtitle {
    text-shadow: 1px 1px #660099;

    background: linear-gradient(180deg, #00FF01 0%, #1F5210 100%);
  }
  .vertical-timeline-element-content {
    text-align: center;
    color: #fff;
    background: linear-gradient(180deg, #00FF01 0%, #1F5210 100%);
    box-shadow: 0px 3px 0px #DDDDDD;
    border-radius: 4px;
    padding: 2em 3em;
    font-size: 2em;


    filter: drop-shadow(-10px 15px 4px #000000) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    
  }
  
  .date {
    font-size: 6em;
    color: #6A0DAD !important;
    
    font-size: 4em !important;
    font: bolder;
    font-weight: 900 !important;
    
  }

  #description {
    margin: 1.25em 0 1.5em 0;
    width: 260px;
    height: 240px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    /* or 233% */
    margin-left: 175px;
    margin-top: -225px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
  }
  
  #header {
    margin: 1.25em 0 1.5em 0;
    width: 260px;
    height: 240px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    /* or 233% */
    margin-left: 175px;
    margin-top: -0px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
  }
  .button {
    text-decoration: none;
    padding: 0.5em 2em;
    border-radius: 5px;
    color: white;
  }
  
  .workButton {
    background-color: #660099;
  }
  
  .workButton:hover {
    background-color: #660099;
  }
  
  .schoolButton {
    background-color: #660099;
  }
  
  .schoolButton:hover {
    background-color: #660099;
  }
 
  
  @media only screen and (max-width: 1700px) {
    .vertical-timeline-element-date {
      display: block !important;
      transform: scale(0.85) !important;
      float: none !important;
      color: rgb(44, 44, 44);
      margin-top: 1.5em;
      
    }
    .per{
      margin: 1.25em 0 1.5em 0;
      width: 260px;
      height: 240px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      /* or 233% */
      margin-left: 175px;
      margin-top: -0px;
      align-items: center;
      text-align: center;
  
      color: #FFFFFF;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .title {
      font-size: 5.5em;
      text-align: center;
    }
    .per {
      text-align: justify;
      width: 225px;
      word-spacing: 0px;
      font-size: 1.75rem !important;
      font-weight: 900;
    }
    
    h3 {
     
      font-size: 1.88rem;
      text-align: center;
     
    }
    
    h5 {
      margin-top: 200px;
      margin-bottom: 200px;
      font-size: 1rem;
     
    }
    .date {
      font-weight: 900 !important;
      font-size: 1.75em !important;
    }
    #description {
      width: 250px;
      height: 300px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 0.9rem;
      line-height: 18px;
  
      color: #FFFFFF;
    }
    .vertical-timeline-element-content {
     
      text-align: center;
      color: #fff;
      background: linear-gradient(180deg, #00FF01 0%, #1F5210 100%);
      box-shadow: 0px 3px 0px #DDDDDD;
      border-radius: 4px;
      padding: 2em 3em;
      font-size: 2em;
  
      
      filter: drop-shadow(-10px 15px 4px #000000) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      zoom: 0.6;
      transform: translateX(35px);
    }
    .vertical-timeline-element-title {

      scroll-margin-bottom: 20px;
    }
  }

.bods {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  
}
.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1100px;
  transform: translateY(-200px);
  z-index: 999999999;
}

.container .card {
  position: relative;
  width:  320px;
  height: 320px;
  margin: 15px;
  overflow: hidden;
}

.container .card .imgBx, 
.container .card .contentBx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}

.container .card .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.container .card .contentBx::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  transform: scaleX(0);
  transition: transform 0.5s ease-in-out;
  transform-origin: right;
  transition-delay: 0.5s;
}

.container .card:hover .contentBx::before {

  transform: scaleX(1);
  transition: transform 0.5s ease-in-out;
  transform-origin: left;
  transition-delay: 0s;
}
.container .card:hover .contentBx{
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .card .contentBx .content {
  position: relative;
  padding: 30px;
  z-index: 1;
  transition: 0.5s;
 transform: translateX(-300px);
 transition-delay: 0s;

}

.container .card:hover .contentBx .content {
  transform: translateX(0px);
  transition-delay: 0.5s;
}

.container .card .contentBx .content h3 {
  font-size: 1.3em;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.container .card .contentBx .content p {
  font-size: 1em;
  line-height: 1.4em;
  font-weight: 300;
}








//center dot for cursor

.dot {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0);
  border: 2px solid white;
  z-index: 999999999;
}
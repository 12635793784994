.home {

    
    @media screen and (max-width: 728px) {
        margin: auto;
        display: block;
        align-items: center;
        min-height: 100%;
        margin: auto;
       
        
    }
}


.about {

    
    @media screen and (max-width: 728px) {
        margin: auto;
        display: block;
        align-items: center;
        min-height: 100%;
        margin: auto;
       
        
    }
}

.works {
  
    @media screen and (max-width: 728px) {
        margin: auto;
        display: block;
        align-items: center;
        min-height: 100%;
        margin: auto;
       
        
    }
}

.projects {

    
    @media screen and (max-width: 728px) {
        margin: auto;
        display: block;
        align-items: center;
        min-height: 100%;
        margin: auto;
       
        
    }
}

.fun {

    
    @media screen and (max-width: 728px) {
        margin: auto;
        display: block;
        align-items: center;
        min-height: 100%;
        margin: auto;
       
        
    }
}